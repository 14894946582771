<template>
  <page-container title="维保组管理" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="维保组名称" prop="maintenancegroupname">
              <a-input v-model.trim="queryParams.maintenancegroupname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <!-- <a-form-model-item label="管理员账号" prop="adminaccount">
              <a-input v-model.trim="queryParams.adminaccount" placeholder="请输入"></a-input>
            </a-form-model-item> -->
            <a-form-model-item label="组长账号" prop="groupmenaccount">
              <a-input v-model.trim="queryParams.groupmenaccount" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData()">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="维保单位" prop="maintenancedepid">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='3'?deptOptions.filter(item => item.deptype=='3'):deptOptionsAll.filter(item => item.deptype=='3')" v-model="deptCascaderSelected" :load-data="userInfo.usertype=='3'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择维保单位" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="服务区域" prop="serveunit">
              <a-select v-model="queryParams.serveunit" placeholder="" show-search :filter-option="filterOption">
                <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.userdepid">{{item.userdepname}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">维保组列表</h3>
          <div class="table-btns">
            <a-button v-if="btnList.includes('新增')" type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="maintenancegroupid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="memberList" slot-scope="value" class="status-view">
            <span class="text" :title="(value && value.length) ? value.map(item => item.username).join(',') : ''">{{(value && value.length) ? value.map(item => item.username).join(',') : ''}}</span>
          </span>
          <span slot="serveUnitsList" slot-scope="value" class="status-view">
            <span class="text" :title="(value && value.length) ? value.map(item => item.userdepname).join(',') : ''">{{(value && value.length) ? value.map(item => item.userdepname).join(',') : ''}}</span>
          </span>
          <span slot="operation" slot-scope="value, record, index">
<!--            <a-button type="link" size="small" @click="showModal('detail', value, record, index)">详情</a-button>-->
<!--            <a-button v-if="btnList.includes('修改')" type="link" size="small" @click="showModal('edit', value, record, index)">修改</a-button>-->
<!--            <a-button v-if="btnList.includes('删除')" type="link" size="small" @click="deleteConfirm(value, record, index)">删除</a-button>-->
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.maintenancegroupid">详情</a-menu-item>
                <a-menu-item v-if="btnList.includes('修改')||userInfo.usertype==0" :key="'edit-'+record.maintenancegroupid">修改</a-menu-item>
                <a-menu-item v-if="btnList.includes('删除')||userInfo.usertype==0" :key="'delete-'+record.maintenancegroupid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="modalVisible" :title="modalTitle" :bodyStyle="{maxHeight: '400px', overflow: 'auto'}">
      <template slot="footer">
        <a-button v-if="modalType=='add'||modalType=='edit'" @click="modalCancel">取消</a-button>
        <a-button type="primary" @click="modalConfirm">确认</a-button>
      </template>
      <a-form-model ref="modalForm" :model="formData" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:14}">
        <a-form-model-item label="维保形式" prop="maintenance_form">
          <a-select :disabled="true" v-model.trim="formData.maintenance_form">
            <a-select-option value="自保">自保</a-select-option>
            <a-select-option value="外委">外委</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="维保组名称" prop="maintenancegroupname">
          <a-input :disabled="modalType==='detail'" v-model.trim="formData.maintenancegroupname" />
        </a-form-model-item>
        <a-form-model-item v-if="formData.maintenance_form=='外委'" label="维保单位" prop="maintenancedepid">
          <a-cascader :disabled="modalType==='detail'" @change="unitChange()" :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='3'?deptOptions.filter(item => item.deptype=='3'):deptOptionsAll.filter(item => item.deptype=='3')" v-model="maintenancedepidList" :load-data="userInfo.usertype=='3'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择维保单位" :change-on-select="true" :allow-clear="false"></a-cascader>
        </a-form-model-item>
        <a-form-model-item v-if="formData.maintenance_form=='自保'" label="自保单位" prop="maintenancedepid">
          <a-cascader :disabled="modalType==='detail'" @change="unitChange()" :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions.filter(item => item.deptype=='1')" v-model="maintenancedepidList" :load-data="deptOnLoadChildren" placeholder="请选择自保单位" :change-on-select="true" :allow-clear="false"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="服务区域" prop="serveunit">
          <a-select mode="multiple" v-model="serveDepList">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.userdepid">{{item.userdepname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="组长" prop="managerUserShow">
          <a-input :value="formData.managerUserShow" disabled style="width: 180px;" />
          <a-button v-if="modalType==='edit'||modalType==='add'" type="primary" @click="openSelectUserModal('组长')" style="margin-left: 10px;">请选择</a-button>
        </a-form-model-item>
        <a-form-model-item label="组员" prop="groupMembersShow">
          <a-input :value="formData.groupMembersShow" disabled style="width: 180px;" />
          <a-button v-if="modalType==='edit'||modalType==='add'" type="primary" @click="openSelectUserModal('组员')" style="margin-left: 10px;">请选择</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- <select-user key="selectadmin" :visible.sync="selectAdminVisible" @get-selected-rows="getAdminUser" select-type="radio" :default-selected="adminUser?[adminUser]:[]"></select-user> -->
    <select-user key="selectmanager" :visible.sync="selectManagerVisible" @get-selected-rows="getManagerUser" select-type="radio" :default-selected="managerUser?[managerUser]:[]" :params="selectUserParams"></select-user>
    <select-user key="selectmembers" :visible.sync="selectGroupMembersVisible" @get-selected-rows="getGroupMembers" :default-selected="groupMembers" :params="selectUserParams"></select-user>
  </page-container>
</template>
<script>
import deptselect from '@/mixins/deptselect'
import { getMentenacegroupListByCondition, addMentenacegroup, modifyMentenacegroup, deleteMentenacegroup, getMentenacegroupById, bindMentenacemember } from 'A/xtpz'
import { getItemFromArrayByKey} from 'U'
import store from '@/store'
import SelectUser from 'C/modals/SelectUser.vue'
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getUserDepNameByCondition} from "A/monitoring";
export default {
  name: 'maintenanceGroupManagement',
  mixins: [deptselect,pagination],
  components: {
    SelectUser
  },
  data() {
    return {
      showAdvanced: false,
      initDeptType: '3',
      breadcrumb: [
        {
          name: '设施设备管理',
          path: ''
        },
        {
          name: '保养管理',
          path: ''
        },
        {
          name: '维保组管理',
          path: ''
        },
        {
          name: '外委维保组',
          path: ''
        },
      ],
      queryParams: {
        maintenancegroupname: '',
        // adminaccount: '',
        groupmenaccount: '',
        maintenancedepid: '',
        maintenance_form:"外委",
        serveunit:'',
      },
      tableColumns: [
        {
          title: '维保组名称',
          dataIndex: 'maintenancegroupname',
          key: 'maintenancegroupname',
          ellipsis: true,
        },
        // {
        //   title: '管理员',
        //   dataIndex: 'adminname',
        //   key: 'adminname',
        // },
        {
          title: '组长',
          dataIndex: 'groupmenname',
          key: 'groupmenname',
          ellipsis: true,
        },
        {
          title: '维保单位',
          dataIndex: 'maintenancedepname',
          key: 'maintenancedepname',
          ellipsis: true,
        },
        {
          title: '维保组成员',
          dataIndex: 'memberList',
          key: 'memberList',
          ellipsis: true,
          scopedSlots: { customRender: 'memberList' }
        },
        {
          title: '服务区域',
          dataIndex: 'serveUnitsList',
          key: 'serveUnitsList',
          ellipsis: true,
          scopedSlots: { customRender: 'serveUnitsList' }
        },
        {
          title: '操作',
          key: 'operation',
          width: 80,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalType: '',
      formData: {
        maintenancegroupid: '',
        maintenancegroupname: '',
        maintenancedepid: '',
        // adminUserShow: '',
        managerUserShow: '',
        groupMembersShow: '',
        maintenance_form:'外委',
        serveunit:'',
      },
      formRules: {
        maintenance_form: [{required: true, message: '请选择维保形式'}],
        maintenancegroupname: [{required: true, message: '请输入维保组名称'}],
        maintenancedepid: [{required: true, message: '请选择维保单位'}],
        serveunit: [{required: true, message: '请选择服务区域'}],
        // adminUserShow: [{required: true, message: '请选择管理员'}],
        managerUserShow: [{required: true, message: '请选择组长'}],
      },
      // selectAdminVisible: false,
      // adminUser: null,
      selectManagerVisible: false,
      managerUser: null,
      selectGroupMembersVisible: false,
      groupMembers: null,
      monitorpointList:[],
      maintenancedepidList: [],
      serveDepList:[],

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    modalTitle() {
      let title = '';
      if(this.modalType == 'add') {
        title = '新增';
      }else if(this.modalType == 'detail') {
        title = '详情';
      }else if(this.modalType == 'edit') {
        title = '修改';
      }else {
        title = '';
      }
      return title;
    },
    selectUserParams() {
      return {
        // userdepid: this.formData.maintenancedepid,
        usertypes: this.formData.maintenance_form=="自保"?"1":"3",
        isequipmentman: this.formData.maintenance_form=="自保"?"false":"true",
        rolename: ""
      }
    }
  },
  watch: {
    maintenancedepidList(val) {
      if(val && val.length) {
        this.formData.maintenancedepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('maintenancedepid');
      }else {
        this.formData.maintenancedepid = ''
      }
    },
    serveDepList(val) {
      if(val && val.length) {
        this.formData.serveunit = val.join('|')
        this.$refs.modalForm.clearValidate('serveunit');
      }else {
        this.formData.serveunit = ''
      }
    },
    managerUser(val) {
      if(val) {
        this.formData.managerUserShow = val.username
        this.$refs.modalForm.clearValidate('managerUserShow');
      }else {
        this.formData.managerUserShow = ''
      }
    },
    groupMembers(val) {
      if(val && val.length) {
        let result = '';
        if(val && val.length) {
          val.forEach(item => {
            if(result) {
              result += ','
            }
            result += item.username
          })
        }
        this.formData.groupMembersShow = result
      }else {
        this.formData.groupMembersShow = ''
      }
    },
    deptCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.maintenancedepid = val[val.length-1];
      }else {
        this.queryParams.maintenancedepid = '';
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
    this.initDeptOptionsAll();
    this.getMonitorPointNameList();
  },
  methods: {
    init() {
      this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
      for(let i=0;i<this.menu.children.length;i++){
        this.btnList.push(this.menu.children[i].menuname)
      }
      this.getTableData();
    },
    getMonitorPointNameList(){
      getUserDepNameByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    resetQueryParams() {
      this.deptCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let params = {
        ...this.queryParams,
        userdepid:this.userInfo.userdepid,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      this.tableLoading = true;
      getMentenacegroupListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.maintenancegroupid);
      }).catch(()=>{
      });
    },
    delete(maintenancegroupid) {
      this.showLoading();
      if(maintenancegroupid) {
        let params = {
          maintenancegroupid
        };
        deleteMentenacegroup(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let maintenancegroupid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'maintenancegroupid', maintenancegroupid);
      if(type == 'delete') {
        this.deleteConfirm(maintenancegroupid, record)
      }else{
        this.showModal(type,record)
      }
    },
    showModal(type,record) {
      this.modalType = type;
      // if(this.userInfo.rolename==='项目工程管理员'){
        this.formData.maintenance_form="外委"
      // }
      if(type == 'add') {
        this.groupMembers=null
        this.managerUser=null
        this.modalVisible = true;
        this.$nextTick(() => {
          this.$refs.modalForm.resetFields();
        })
        this.maintenancedepidList=[]
        this.serveDepList=[]
      }else {
        this.initDeptOptionsByUserdepid(record.maintenancedepid, 'maintenancedepidList', this.userInfo.usertype != '3')
        this.serveDepList = record.serveUnitsList ? record.serveUnitsList.map(item => item.userdepid) : [];
        this.modalVisible = true;
        this.showLoading();
        this.$nextTick(() => {
          let params = {
            maintenancegroupid: record.maintenancegroupid,
          }
          getMentenacegroupById(params).then(res => {
            this.hideLoading();
            if(res && res.returncode == '0') {
              record = res.item;
              this.formData.maintenancegroupid = record.maintenancegroupid;
              this.formData.maintenancedepid = record.maintenancedepid;
              this.initDeptOptionsByUserdepid(record.maintenancedepid, 'maintenancedepidList')
              this.formData.maintenancegroupname = record.maintenancegroupname;
              this.formData.maintenance_form = record.maintenance_form;
              this.formData.serveunit = record.serveunit;
              if(record.groupmenid) {
                this.managerUser = {
                  userid: record.groupmenid,
                  username: record.groupmenname,
                };
              }else {
                this.managerUser = null;
              }
              if(record.memberList && record.memberList.length) {
                this.groupMembers = record.memberList;
                console.log(this.groupMembers)
              }else {
                this.groupMembers = null;
              }
            }else {
              this.$message.error(res.errormsg||'查询失败');
            }
          })
        })
      }
    },
    modalCancel() {
      this.modalVisible = false;
    },
    resetModal() {
      this.$refs.modalForm.resetFields();
    },
    modalConfirm() {
      if(this.modalType == 'add' || this.modalType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if(valid) {
            this.addOrEdit();
          }else {
            return false;
          }
        })
      }else {
        this.modalVisible = false;
      }
    },
    addOrEdit() {
      this.showLoading();
      let memberids = '';
      if(this.groupMembers && this.groupMembers.length) {
        memberids = this.groupMembers.map(item => item.userid).join('|')
      }else if(this.formData.groupMembersShow) {
        memberids = this.formData.groupMembersShow
      }
      let params = {
        maintenancegroupname: this.formData.maintenancegroupname,
        maintenancedepid: this.formData.maintenancedepid,
        maintenance_form: this.formData.maintenance_form,
        serveunit: this.formData.serveunit,
        // adminid: this.adminUser ? this.adminUser.userid : this.formData.adminUserShow,
        groupmenid: this.managerUser ? this.managerUser.userid : this.formData.managerUserShow,
        memberids: memberids.length!==0?memberids:'',
      };
      if(this.modalType == 'add') {
        addMentenacegroup(params).then(this.addOrEditResult);
      }else if(this.modalType == 'edit') {
        params.maintenancegroupid = this.formData.maintenancegroupid
        modifyMentenacegroup(params).then(this.addOrEditResult)
      }
    },
    addOrEditResult(res) {
      this.hideLoading();
      if(res && res.returncode == '0') {
        this.$message.success('操作成功');
        this.modalVisible = false;
        this.getTableData();
      }else {
        this.$message.error(res.errormsg||'操作失败');
      }
    },
    // getAdminUser(selectedRows) {
    //   this.adminUser = selectedRows[0];
    // },
    getManagerUser(selectedRows) {
      this.managerUser = selectedRows[0];
    },
    getGroupMembers(selectedRows) {
      this.groupMembers = selectedRows;
    },
    openSelectUserModal(type){
      if(type==="组长"){
        this.selectUserParams.rolename="组长"
        this.selectManagerVisible=true
      }else if(type==="组员"){
        this.selectUserParams.rolename="组员"
        this.selectGroupMembersVisible=true
      }
    },
    unitChange() {
      this.formData.managerUserShow = []
      this.formData.groupMembersShow = []
      this.managerUser=null
      this.groupMembers=null
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>